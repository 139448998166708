<template>
  <div class="spartan-plus-ticket">
    <img
      class="ticket-logo"
      src="@/assets/spartan_plus_dark.svg"
      alt="spartan+"
    />
    <div class="ticket-description">
      <span v-if="showTrialState">{{
        $t("checkout_spartan_plus_trial_ticket_description")
      }}</span>
      <span v-else>{{ $t("checkout_spartan_plus_ticket_description") }}</span>
    </div>
    <template>
      <i18n
        v-if="showTrialState"
        tag="div"
        class="ticket-subline"
        path="checkout_spartan_plus_trial_ticket_subline"
      >
        <a
          href="https://www.spartan.com/pages/spartan-plus-membership"
          target="_blank"
          >{{ $t("checkout_spartan_plus_ticket_subline_link") }}</a
        >
      </i18n>
      <i18n
        v-else
        tag="div"
        class="ticket-subline"
        path="checkout_spartan_plus_ticket_subline"
      >
        <a
          href="https://www.spartan.com/pages/spartan-plus-membership"
          target="_blank"
          >{{ $t("checkout_spartan_plus_ticket_subline_link") }}</a
        >
      </i18n>
    </template>

    <div class="ticket-controls">
      <div class="ticket-price">
        <span v-if="showTrialState">
          {{ $t("checkout_spartan_plus_trial_ticket_price") }}
        </span>
        <span v-else>
          {{ $t("checkout_spartan_plus_ticket_price") }}
        </span>
      </div>

      <mem-button
        v-if="!orderSpartanPlusTicket"
        @click="addTicket"
        :loading="processing"
        >{{ $t("add") }}</mem-button
      >
      <mem-button
        v-if="orderSpartanPlusTicket"
        :loading="processing"
        btn-type="tertiary-light"
        @click="removeTicket"
        >{{ $t("question_multiple_added_lg") }}</mem-button
      >
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  props: {
    ticket: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    processing: false,
  }),
  computed: {
    ...mapGetters(["checkoutRegion", "orderSpartanPlusTicket", "userData"]),
    showTrialState() {
      return this.userData.subscription_trial_allowed;
    },
  },
  methods: {
    ...mapActions(["updateTicketsListItem"]),
    ...mapMutations(["setSpartanPlusTicket"]),
    async addTicket() {
      this.processing = true;
      await this.updateTicketsListItem({
        ticket: {
          ts_event_id: this.ticket.eventId,
          ts_ticket_type_id: this.ticket.ticketTypeId,
          country: this.checkoutRegion,
        },
        quantity: 1,
      });
      this.processing = false;
      this.setSpartanPlusTicket(true);
      this.$emit("on-change");
    },
    async removeTicket() {
      this.processing = true;
      await this.updateTicketsListItem({
        ticket: {
          ts_event_id: this.ticket.eventId,
          ts_ticket_type_id: this.ticket.ticketTypeId,
          country: this.checkoutRegion,
        },
        quantity: 0,
      });
      this.processing = false;
      this.setSpartanPlusTicket(false);
      this.$emit("on-change");
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;

.spartan-plus-ticket {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  @media screen and (max-width: $mobile-view) {
    background-color: #ffffff;
    margin-bottom: 8px;
    padding: 24px;
  }
  &::before {
    content: " ";
    display: flex;
    height: 1px;
    margin-bottom: 40px;
    background-color: #cfcfcf;
    @media screen and (max-width: $mobile-view) {
      display: none;
    }
  }
  .ticket-logo {
    width: 150px;
    height: 40px;
    @media screen and (max-width: $mobile-view) {
      width: 114px;
      height: 32px;
    }
  }
  .ticket-description {
    margin-top: 10px;
    margin-bottom: 22px;

    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    @media screen and (max-width: $mobile-view) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .ticket-subline {
    color: #4f4f4f;

    font-size: 12px;
    line-height: 16px;
    a {
      color: inherit;
    }
  }
  .ticket-controls {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    margin-top: 10px;
    @media screen and (max-width: $mobile-view) {
      margin-top: 20px;
    }
    .ticket-price {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
    button {
      @media screen and (max-width: $mobile-view) {
        height: 36px;
        padding: 0 16px;
      }
      // height: 42px;
    }
  }
}
</style>